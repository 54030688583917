import React, { useEffect, useState } from "react";
import { db } from "../../App";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Space, Input, Table } from "antd";
import Description from "../common/Description";
import moment from "moment";
import { formatter } from "../../helpers/Helpers";
import {
  addAccountPayments,
  addFilteredAccountsPayments,
  selectAccountPayments,
  selectFilteredAccountsPayments,
} from "../../features/accountSlice";

const { Search } = Input;

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Customer",
    dataIndex: "customerName",
    key: "customerName",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Paid Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Payment Method",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    render: (text) => <p>{text?.label}</p>,
  },
  {
    title: "Payment Date",
    dataIndex: "date",
    key: "date",
    render: (text) => (
      <p>{moment.unix(text?.seconds || text?._seconds).format("DD-MM-YYYY")}</p>
    ),
  },
  {
    title: "Description",
    key: "description",
    render: (_, payment) => (
      <div className="flex flex-row gap-1 justify-start">
        <Description data={payment} title={"Customer Payment Descriptions"} />
      </div>
    ),
  },
];

const AccountPayments = () => {
  const dispatch = useDispatch();

  const [pageLoading, setPageLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState(false);

  useEffect(() => {
    const getCustomerPayments = async () => {
      let paymentsArray = [];

      setPageLoading(true);
      const querySnapshot = await getDocs(collection(db, "customerPayments"));
      querySnapshot.forEach((doc) => {
        //set data
        const data = doc.data();
        paymentsArray.push(data);
      });

      if (paymentsArray.length > 0) {
        dispatch(addAccountPayments(paymentsArray));
        setPageLoading(false);
      } else {
        dispatch(addAccountPayments([]));
        setPageLoading(false);
      }
    };

    getCustomerPayments();
  }, [dispatch]);

  const payments = useSelector(selectAccountPayments);

  const sortedPayments = payments
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((payment, index) => ({ ...payment, key: index + 1 }));

  const handleOnSearchChange = () => {
    if (searchText) {
      const text = searchText.toLocaleLowerCase();
      const searchedPayments = payments.filter((payment) => {
        const name = payment?.paymentType?.toLocaleLowerCase() || "";
        return name.includes(text);
      });

      // Update state with filtered payments
      dispatch(addFilteredAccountsPayments(searchedPayments));
      setFilters(true);
    } else {
      // Update state with filtered payments
      dispatch(addFilteredAccountsPayments([]));
      setFilters(false);
    }
  };

  const handleSearchText = (value) => {
    if (value) {
      setSearchText(value);
    } else {
      // Update state with filtered payments
      dispatch(addFilteredAccountsPayments([]));
      setFilters(false);
      setSearchText(value);
    }
  };

  const filteredPayments = useSelector(selectFilteredAccountsPayments);

  const sortedFilteredPayments = filteredPayments
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((payment, index) => ({ ...payment, key: index + 1 }));

  return (
    <div className="relative">
      {pageLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-hidden z-10">
          <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-70 flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 mb-4"></div>
          </div>
        </div>
      )}
      <div className="px-2">
        <div className="flex flex-row justify-end items-end py-2 px-2 w-full">
          <div className="">
            <Space.Compact size="large">
              <Search
                placeholder="Search payment method"
                allowClear
                onChange={(e) => handleSearchText(e.target.value)}
                onSearch={() => handleOnSearchChange()}
              />
            </Space.Compact>
          </div>
        </div>
        <div className="pt-4">
          {filters ? (
            <>
              <div className="pt-4">
                <Table
                  columns={columns}
                  dataSource={sortedFilteredPayments}
                  size="middle"
                  pagination={{ defaultPageSize: 10, size: "middle" }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="pt-4">
                <Table
                  columns={columns}
                  dataSource={sortedPayments}
                  size="middle"
                  pagination={{ defaultPageSize: 10, size: "middle" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountPayments;
