import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../assets/utils/colors";
import {
  GiGasPump,
  GiMoneyStack,
  GiTakeMyMoney,
  GiReceiveMoney,
  GiPayMoney,
} from "react-icons/gi";
import { LuGauge } from "react-icons/lu";
import { MdOutlineAccountBalance, MdOutlinePointOfSale } from "react-icons/md";
import { Segmented } from "antd";
import { DatePicker, Space } from "antd";
import { Pie, measureTextWidth, Line, Column } from "@ant-design/plots";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  addAppAnalysis,
  selectAvailableStock,
  selectFuelAnalytics,
  selectStationSalesAnalytics,
  selectStationsSalesPercentage,
  selectTotalCashSales,
  selectTotalDebtSales,
  selectTotalExpensesAmount,
  selectTotalPurchases,
  selectTotalSales,
  selectTotalStations,
} from "../features/appSlice";
import { formatter } from "../helpers/Helpers";
import moment from "moment/moment";

const DemoPie = ({ data }) => {
  let annotationText = "";
  if (data?.length > 0) {
    annotationText = "Sales\n100%";
  }
  const config = {
    data,
    angleField: "value",
    colorField: "type",
    paddingRight: 70,
    innerRadius: 0.64,
    radius: 1,
    label: {
      text: "value",
      style: {
        textAlign: "center",
      },
    },
    legend: {
      color: {
        title: false,
        rowPadding: 5,
      },
    },
    tooltip: false,
    interaction: {
      elementHighlight: true,
    },
    annotations: [
      {
        type: "text",
        style: {
          text: annotationText,
          x: "50%",
          y: "50%",
          textAlign: "center",
          fontSize: 16,
        },
        tooltip: false,
      },
    ],
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  return <Pie {...config} />;
};

const DemoColumn = ({ data }) => {
  const config = {
    data,
    xField: "month",
    yField: "value",
    colorField: "name",
    group: true,
    style: {
      inset: 2,
    },
    // axis: {
    //   y: {
    //     labelFormatter: (v) => `${(v / 1000).toFixed(0)} K`,
    //   },
    // },
  };

  return (
    <div style={{ width: "750px", height: "240px" }}>
      <Column
        {...config}
        options={{
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
};

const DemoLine = () => {
  const data = [
    { year: "1991", value: 3 },
    { year: "1992", value: 4 },
    { year: "1993", value: 3.5 },
    { year: "1994", value: 5 },
    { year: "1995", value: 4.9 },
    { year: "1996", value: 6 },
    { year: "1997", value: 7 },
    { year: "1998", value: 9 },
    { year: "1999", value: 13 },
  ];
  const config = {
    data,
    xField: "year",
    yField: "value",
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
  };
  return <Line {...config} />;
};

const Home = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const dispatch = useDispatch();

  const functions = getFunctions();
  let totalProfit = 0;

  const maxYear = moment().year(); // Current year

  const disabledDate = (current) => {
    return current && current.year() > maxYear;
  };

  useEffect(() => {
    const getAnalyticsData = async () => {
      try {
        //fetch day book
        setPageLoading(true);
        const analyticsData = httpsCallable(functions, "fetchAnalytics");
        analyticsData({ analytics: true })
          .then((result) => {
            // Read result of the Cloud Function.
            const data = result.data;
            //add day
            const dashoardAnalysis = data?.data;
            console.log(dashoardAnalysis);
            dispatch(addAppAnalysis(dashoardAnalysis));
            setPageLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setPageLoading(false);
          });
      } catch (error) {
        console.log(error);
        setPageLoading(false);
      }
    };

    getAnalyticsData();
  }, [dispatch]);

  const totalStations = useSelector(selectTotalStations);
  const totalSales = useSelector(selectTotalSales);
  const totalCashSales = useSelector(selectTotalCashSales);
  const totalDebtSales = useSelector(selectTotalDebtSales);
  const totalExpensesAmount = useSelector(selectTotalExpensesAmount);
  const availableStock = useSelector(selectAvailableStock);
  const totalPurchases = useSelector(selectTotalPurchases);
  const fuelAnalytics = useSelector(selectFuelAnalytics);
  const data = useSelector(selectStationSalesAnalytics);
  const stationsSalesPercentage = useSelector(selectStationsSalesPercentage);

  //   const data = [
  //     {
  //       name: "Handeni",
  //       year: "2024",
  //       sales: 130000000,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2025",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2026",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2027",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2028",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2029",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2030",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2031",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2032",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2033",
  //       sales: 0,
  //     },
  //     {
  //       name: "Handeni",
  //       year: "2034",
  //       sales: 0,
  //     },

  const config = {
    data,
    xField: "year",
    yField: "value",
    seriesField: "stationName",
    yAxis: {
      label: {
        formatter: (v) => `${(v / 1000000).toFixed(0)} M`,
      },
    },
    legend: {
      position: "top",
    },
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  const onFuelYearChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div className="">
      <Grid container sx={{ px: 2 }}>
        <Grid item sm={12}>
          <div className="w-full grid grid-cols-4 gap-2 justify-center items-center">
            {[
              {
                icon: <GiGasPump />,
                label: "Total Stations",
                value: totalStations,
              },
              {
                icon: <MdOutlinePointOfSale />,
                label: "Total Sales",
                value: `TZS ${formatter.format(totalSales)}`,
              },
              {
                icon: <GiMoneyStack />,
                label: "Total Cash Sales",
                value: `TZS ${formatter.format(totalCashSales)}`,
              },
              {
                icon: <GiReceiveMoney />,
                label: "Total Debtors Sales",
                value: `TZS ${formatter.format(totalDebtSales)}`,
              },
              {
                icon: <LuGauge />,
                label: "Available Stock",
                value: `${availableStock} Litres`,
              },
              {
                icon: <GiTakeMyMoney />,
                label: "Total Purchases",
                value: `TZS ${formatter.format(totalPurchases)}`,
              },
              {
                icon: <GiPayMoney />,
                label: "Total Expenses",
                value: `TZS ${formatter.format(totalExpensesAmount)}`,
              },
              {
                icon: <MdOutlineAccountBalance />,
                label: "Total Profit",
                value: `TZS ${formatter.format(totalProfit)}`,
              },
            ].map((item, index) => (
              <Card
                key={index}
                sx={{
                  width: 250,
                  height: 80,
                  background: `linear-gradient(270deg, ${colors.bgColor1}, ${colors.primary}) !important`,
                }}
              >
                <div className="flex flex-row justify-between px-2 ">
                  <h4 className="text-white text-sm font-light"></h4>
                  <div className="text-3xl text-white">{item.icon}</div>
                </div>
                <div className="px-2">
                  <h4 className="text-white text-xl font-light">
                    {item.value}
                  </h4>
                  <h4 className="text-white text-sm font-light pb-0.5">
                    {item.label}
                  </h4>
                </div>
              </Card>
            ))}
          </div>
        </Grid>
      </Grid>

      {/* bar chart */}
      <div className="py-2 px-4">
        <div className="w-full flex flex-row gap-2">
          {/* Fuel Type Analytics */}
          <div className="w-[75%] h-[280px] border-[1px] border-primaryColor rounded-lg">
            <div className="flex flex-row justify-between py-1 px-2">
              <h4>
                Fuel Type Analytics <span className="text-xs">(Litres)</span>
              </h4>
              <div>
                <DatePicker
                  onChange={onFuelYearChange}
                  picker="year"
                  disabledDate={disabledDate}
                />
              </div>
            </div>
            <div className="pl-1">
              <DemoColumn data={fuelAnalytics} />
            </div>
          </div>

          {/* Stations Sales Percentage */}
          <div className="w-[25%] h-[280px] border-[1px] border-primaryColor rounded-lg">
            <h4 className="text-sm font-light px-2">Stations Sales %</h4>
            <DemoPie data={stationsSalesPercentage} />
          </div>
        </div>
      </div>

      {/* <div className="py-2 px-4">
        <div className="w-full flex flex-row gap-2">
          <div className="w-[100%] h-[280px] border-[1px] border-primaryColor rounded-lg">
            <div className="flex flex-row justify-between py-1 px-2">
              <h4>Sales Analytics</h4>
              <div>
                <Segmented
                  size="small"
                  options={["Daily", "Monthly", "Yearly"]}
                  onChange={(value) => {
                    console.log(value); // string
                  }}
                />
              </div>
            </div>
            <div className="pl-1">
              <Line
                {...config}
                style={{
                  width: 1000,
                  height: 240,
                }}
                options={{
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
