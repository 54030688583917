import React, { useEffect } from "react";
import { db } from "../../../App";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import {
  addStationExcessiveStocks,
  addStationInfo,
  selectStationExcessiveStock,
  selectStationInfo,
} from "../../../features/stationSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formatter } from "../../../helpers/Helpers";
import { Chip, Divider } from "@mui/material";
import Description from "../../common/Description";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "AGO",
    key: "ago",
    render: (_, transfer) => (
      <p className="flex flex-col">
        <span>{formatter.format(transfer?.agoLitres)} Litres</span>
        <span>TZS {formatter.format(transfer?.agoTotalPrice)}</span>
      </p>
    ),
  },
  {
    title: "PMS",
    key: "pms",
    render: (_, transfer) => (
      <p className="flex flex-col">
        <span>{formatter.format(transfer?.pmsLitres)} Litres</span>
        <span>TZS {formatter.format(transfer?.pmsTotalPrice)}</span>
      </p>
    ),
  },
  {
    title: "Totals",
    key: "total",
    render: (_, transfer) => (
      <p className="flex flex-col">
        <span>
          {formatter.format(parseFloat(transfer?.totalLitres || 0))} Litres
        </span>
        <span>TZS {formatter.format(transfer?.totalPrice)}</span>
      </p>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text) => <p>{moment.unix(text?.seconds).format("DD-MM-YYYY")}</p>,
  },
  {
    title: "Description",
    key: "view",
    render: (_, transfer) => (
      <div className="flex flex-row gap-1 justify-start">
        <Description
          data={transfer}
          title={"Station Excessive  Stock Descriptions"}
        />
      </div>
    ),
  },
];

const StationExcessiveStock = () => {
  const dispatch = useDispatch();
  const { stationID } = useParams();

  useEffect(() => {
    const getStationExcessiveStock = async () => {
      let stockArray = [];

      const querySnapshot = await getDocs(
        collection(db, "stations", stationID, "excessiveStocks")
      );
      querySnapshot.forEach((doc) => {
        //set data
        const data = doc.data();
        stockArray.push(data);
      });

      if (stockArray.length > 0) {
        dispatch(addStationExcessiveStocks(stockArray));
      }
    };

    const getStation = async () => {
      try {
        const docRef = doc(db, "stationBucket", stationID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          dispatch(addStationInfo(data));
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
          dispatch(addStationInfo({}));
        }
      } catch (e) {
        console.log(e);
      }
    };

    getStationExcessiveStock();
    getStation();
  }, [dispatch, stationID]);

  const stocks = useSelector(selectStationExcessiveStock);
  const station = useSelector(selectStationInfo);
  console.log(station);

  const agoStock = stocks.reduce(
    (sum, stock) => sum + parseFloat(stock.agoLitres),
    0
  );
  const pmsStock = stocks.reduce(
    (sum, stock) => sum + parseFloat(stock.pmsLitres),
    0
  );
  const totalStocks = agoStock + pmsStock;

  const sortedStocks = stocks
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((stock, index) => ({ ...stock, key: index + 1 }));

  return (
    <div className="px-2">
      <div className="my-1">
        <Divider>
          <Chip
            label={`RECEIVED AGO LITRES: ${formatter.format(agoStock || 0)}`}
          />{" "}
          <Chip
            label={`RECEIVED PMS LITRES: ${formatter.format(pmsStock || 0)}`}
          />{" "}
          <Chip
            label={`TOTAL RECEIVED LITRES: ${formatter.format(
              totalStocks || 0
            )}`}
          />{" "}
        </Divider>
      </div>
      <div className="pt-8">
        <Table
          columns={columns}
          dataSource={sortedStocks}
          size="middle"
          pagination={{ defaultPageSize: 10, size: "middle" }}
        />
      </div>
    </div>
  );
};

export default StationExcessiveStock;
