import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { message } from "antd";
import AccountCircle from "@mui/icons-material/AccountCircle";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  const signInUser = () => {
    if (!email) {
      message.warning("Please enter your email");
    } else if (!password) {
      message.warning("Please enter your password");
    } else {
      setLoading(true);

      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          setLoading(false);
          navigate(`/`);
          message.success("Logged in");
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
    }
  };

  return (
    <div className="w-screen h-screen bg-[#121213] flex justify-center items-center">
      <div className="w-[30%] rounded-lg bg-white shadow-lg">
        <div className="p-3 sm:p-10">
          <div className="text-center">
            <h1 className="my-2 text-4xl font-bold">Welcome</h1>
            <AccountCircle sx={{ fontSize: "100px" }} />
            <p className="text-sm dark:text-gray-400">
              Please sign in to access your account
            </p>
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <div>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Username"
                  autoFocus
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p className="cursor-pointer text-[#0A365C] text-right hover:underline text-sm">
                  Forgot Password?
                </p>
              </div>
            </div>
            <div>
              <button
                type="button"
                onClick={signInUser}
                disabled={loading}
                className={`w-full h-14 px-8 py-3 rounded-md text-white ${
                  loading
                    ? "bg-[#0A365C] opacity-25 cursor-not-allowed"
                    : "bg-[#0A365C] hover:bg-[#54728D]"
                }`}
              >
                {loading ? "Loading..." : "Sign in"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
