import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AppLayout from "../pages/layouts/AppLayout";
import Login from "../pages/auth/Login";
import Home from "../pages/Home";
import Users from "../pages/users/Users";
import Profile from "../pages/users/Profile";
import Station from "../pages/stations/Station";
import Customer from "../pages/customers/Customer";
import Stations from "../pages/stations/Stations";
import Settings from "../pages/settings/Settings";
import Transactions from "../pages/transactions/Transactions";
import Expenses from "../pages/expenses/Expenses";
import Reports from "../pages/reports/Reports";
import Accounts from "../pages/accounts/Accounts";
import Pump from "../pages/stations/pumps/Pump";
import Suppliers from "../pages/suppliers/Suppliers";
import Supplier from "../pages/suppliers/Supplier";
import StockManager from "../pages/stock/StockManager";
import StationAccount from "../pages/stations/accounts/StationAccount";
import CustomerManager from "../pages/customers/CustomerManager";
import ManagerStation from "../pages/manager/ManagerStation";
import ManagerSettings from "../pages/manager/ManagerSettings";
import ManagerReports from "../pages/manager/ManagerReports";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../features/userSlice";
import ManagerHome from "../pages/ManagerHome";

const LoginElement = () => <Login />;

const DashboardElement = () => (
  <AppLayout>
    <Home />
  </AppLayout>
);

const ManagerDashboardElement = () => (
  <AppLayout>
    <ManagerHome />
  </AppLayout>
);

const UsersElement = () => (
  <AppLayout>
    <Users />
  </AppLayout>
);

const SettingElement = () => (
  <AppLayout>
    <Settings />
  </AppLayout>
);

const StationsElement = () => (
  <AppLayout>
    <Stations />
  </AppLayout>
);

const StationElement = () => (
  <AppLayout>
    <Station />
  </AppLayout>
);

const CustomersElement = () => (
  <AppLayout>
    <CustomerManager />
  </AppLayout>
);

const CustomerElement = () => (
  <AppLayout>
    <Customer />
  </AppLayout>
);

const ProfileElement = () => (
  <AppLayout>
    <Profile />
  </AppLayout>
);

const ExpensesElement = () => (
  <AppLayout>
    <Expenses />
  </AppLayout>
);

const TransactionsElement = () => (
  <AppLayout>
    <Transactions />
  </AppLayout>
);

const ReportsElement = () => (
  <AppLayout>
    <Reports />
  </AppLayout>
);

const AccountsElement = () => (
  <AppLayout>
    <Accounts />
  </AppLayout>
);

const PumpElement = () => (
  <AppLayout>
    <Pump />
  </AppLayout>
);

const StocksElement = () => (
  <AppLayout>
    <StockManager />
  </AppLayout>
);

const SuppliersElement = () => (
  <AppLayout>
    <Suppliers />
  </AppLayout>
);

const SupplierElement = () => (
  <AppLayout>
    <Supplier />
  </AppLayout>
);

const StationAccountElement = () => (
  <AppLayout>
    <StationAccount />
  </AppLayout>
);

const ManagerStationElement = () => (
  <AppLayout>
    <ManagerStation />
  </AppLayout>
);

const ManagerReportsElement = () => (
  <AppLayout>
    <ManagerReports />
  </AppLayout>
);

const StationSettingsElement = () => (
  <AppLayout>
    <ManagerSettings />
  </AppLayout>
);

const App = () => {
  const user = useSelector(selectUserInfo);
  const role = user?.role;

  if (role === null || role === undefined) {
    return (
      <div className="relative h-screen">
        <div className="py-4 w-full flex justify-center items-center overflow-hidden h-full">
          <div className="absolute bg-white bg-opacity-70 z-10 inset-0 flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 mb-4"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Routes>
        <Route path="/login" element={<LoginElement />} />
        <Route
          path="/"
          element={
            role === "manager" ? (
              <Navigate to="/station-home" />
            ) : (
              <Navigate to="/home" />
            )
          }
        />
        <Route path="/users" element={<UsersElement />} />
        <Route path="/settings" element={<SettingElement />} />
        <Route path="/stations" element={<StationsElement />} />
        <Route path="/customers" element={<CustomersElement />} />
        <Route path="/transactions" element={<TransactionsElement />} />
        <Route path="/expenses" element={<ExpensesElement />} />
        <Route path="/profile" element={<ProfileElement />} />
        <Route path="/reports" element={<ReportsElement />} />
        <Route path="/accounts" element={<AccountsElement />} />
        <Route path="/stocks" element={<StocksElement />} />
        <Route path="/suppliers" element={<SuppliersElement />} />
        <Route path="/customers/:customerID" element={<CustomerElement />} />
        <Route path="/stations/:stationID" element={<StationElement />} />
        <Route path="/suppliers/:supplierID" element={<SupplierElement />} />
        <Route path="/home" element={<DashboardElement />} />

        {/* //station or manager routes */}
        <Route path="/station-home" element={<ManagerDashboardElement />} />
        <Route path="/station-reports" element={<ManagerReportsElement />} />
        <Route path="/station/:stationID" element={<ManagerStationElement />} />
        <Route
          path="/station-settings/:stationID"
          element={<StationSettingsElement />}
        />

        {/* //shared by both manager and other user */}
        <Route
          path="/stations/:stationID/pumps/:pumpID"
          element={<PumpElement />}
        />
        <Route
          path="/stations/:stationID/accounts/:dayBookID"
          element={<StationAccountElement />}
        />
        <Route
          path="/station/:stationID/pumps/:pumpID"
          element={<PumpElement />}
        />
        <Route
          path="/station/:stationID/accounts/:dayBookID"
          element={<StationAccountElement />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default App;
