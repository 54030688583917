import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { formatter } from "../../helpers/Helpers";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  addAccountSales,
  selectAccountSales,
} from "../../features/accountSlice";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Date",
    dataIndex: "day",
    key: "day",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Station",
    dataIndex: "stationName",
    key: "stationName",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Quantity",
    dataIndex: "totalLitres",
    key: "totalLitres",
    render: (text) => <p>{formatter.format(text)} Litres</p>,
  },
  {
    title: "Cash Sales",
    dataIndex: "totalCash",
    key: "totalCash",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Debtor Sales",
    dataIndex: "totalDebtorsAmount",
    key: "totalDebtorsAmount",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Total Sales",
    dataIndex: "totalSales",
    key: "totalSales",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Total Expenses",
    dataIndex: "totalExpensesAmount",
    key: "totalExpensesAmount",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  //   {
  //     title: "Actions",
  //     key: "action",
  //     render: (_, book) => (
  //       <p className="flex flex-row gap-1 justify-start">
  //         <ViewBook book={book} />
  //       </p>
  //     ),
  //   },
];

// const ViewBook = ({ book }) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { stationID } = useParams();

//   const handleViewBook = () => {
//     // dispatch(addSupplierDetails(supplier));
//     navigate(`/stations/${stationID}/accounts/${book?.dayBook?.id}`);
//   };

//   return (
//     <p className="mt-1">
//       <IconButton onClick={() => handleViewBook()}>
//         <RemoveRedEye className="text-[#0A365C] text-xl cursor-pointer" />
//       </IconButton>
//     </p>
//   );
// };

const AccountSales = () => {
  const [pageLoading, setPageLoading] = useState(false);

  const dispatch = useDispatch();
  const functions = getFunctions();

  useEffect(() => {
    const getAccounts = async () => {
      try {
        //fetch day book
        setPageLoading(true);
        const daySalesBook = httpsCallable(functions, "fetchSalesBook");
        daySalesBook({ stationID: "", allStations: true })
          .then((result) => {
            // Read result of the Cloud Function.
            const data = result.data;
            //add day
            const accounts = data?.data;
            // console.log(accounts);
            dispatch(addAccountSales(accounts));
            setPageLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setPageLoading(false);
          });
      } catch (error) {
        console.log(error);
        setPageLoading(false);
      }
    };

    getAccounts();
  }, [dispatch]);

  const accounts = useSelector(selectAccountSales);

  const sortedAccounts = accounts
    ?.slice()
    .sort((a, b) => new Date(b.day) - new Date(a.day))
    .map((sale, index) => ({ ...sale, key: index + 1 }));

  return (
    <div className="relative">
      {pageLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-hidden z-10">
          <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-70 flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 mb-4"></div>
          </div>
        </div>
      )}
      <div className="px-2 py-2">
        <div className="pt-4">
          <Table
            columns={columns}
            dataSource={sortedAccounts}
            size="middle"
            pagination={{ defaultPageSize: 10, size: "middle" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountSales;
