import React, { useEffect, useState } from "react";
import { db } from "../../App";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Space, Input, Table } from "antd";
import Description from "../common/Description";
import moment from "moment";
import { formatter } from "../../helpers/Helpers";
import {
  addAccountPurchases,
  addFilteredAccountsPurchases,
  selectAccountPurchases,
  selectFilteredAccountsPurchases,
} from "../../features/accountSlice";

const { Search } = Input;

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "AGO",
    dataIndex: "agoLitres",
    key: "agoLitres",
    render: (text) => <p>{formatter.format(text)} Litres</p>,
  },
  {
    title: "AGO Price",
    dataIndex: "agoPrice",
    key: "agoPrice",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "PMS",
    dataIndex: "pmsLitres",
    key: "pmsLitres",
    render: (text) => <p>{formatter.format(text)} Litres</p>,
  },
  {
    title: "PMS Price",
    dataIndex: "pmsPrice",
    key: "pmsPrice",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Total Quantity",
    dataIndex: "totalLitres",
    key: "totalLitres",
    render: (text) => <p>{formatter.format(text)} Litres</p>,
  },
  {
    title: "Total Amount",
    dataIndex: "totalPrice",
    key: "totalPrice",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Supplier",
    dataIndex: "supplierName",
    key: "supplierName",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text) => (
      <p>{moment.unix(text?.seconds || text?._seconds).format("DD-MM-YYYY")}</p>
    ),
  },
  {
    title: "View",
    key: "view",
    render: (_, stock) => (
      <div className="flex flex-row gap-1 justify-start">
        <Description data={stock} title={"Stock Purchase Descriptions"} />
      </div>
    ),
  },
];

const AccountPurchases = () => {
  const dispatch = useDispatch();

  const [pageLoading, setPageLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState(false);

  useEffect(() => {
    const getPurchases = async () => {
      let purchaseArray = [];

      setPageLoading(true);
      const querySnapshot = await getDocs(collection(db, "stockBucket"));
      querySnapshot.forEach((doc) => {
        //set data
        const data = doc.data();
        purchaseArray.push(data);
      });

      if (purchaseArray.length > 0) {
        dispatch(addAccountPurchases(purchaseArray));
        setPageLoading(false);
      } else {
        dispatch(addAccountPurchases([]));
        setPageLoading(false);
      }
    };

    getPurchases();
  }, [dispatch]);

  const purchases = useSelector(selectAccountPurchases);

  const sortedPurchases = purchases
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((purchase, index) => ({ ...purchase, key: index + 1 }));

  const handleOnSearchChange = () => {
    if (searchText) {
        const text = searchText.toLocaleLowerCase();
        const searchedPayments = purchases.filter((purchase) => {
          const name = purchase?.supplierName?.toLocaleLowerCase() || "";
          return name.includes(text);
        });

      // Update state with filtered payments
      dispatch(addFilteredAccountsPurchases(searchedPayments));
      setFilters(true);
    } else {
      // Update state with filtered payments
      dispatch(addFilteredAccountsPurchases([]));
      setFilters(false);
    }
  };

  const handleSearchText = (value) => {
    if (value) {
      setSearchText(value);
    } else {
      // Update state with filtered payments
      dispatch(addFilteredAccountsPurchases([]));
      setFilters(false);
      setSearchText(value);
    }
  };

  const filteredPurchases = useSelector(selectFilteredAccountsPurchases);

  const sortedFilteredPurchases = filteredPurchases
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((purchase, index) => ({ ...purchase, key: index + 1 }));

  return (
    <div className="relative">
      {pageLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-hidden z-10">
          <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-70 flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 mb-4"></div>
          </div>
        </div>
      )}
      <div className="px-2">
        <div className="flex flex-row gap-8 justify-end items-end py-2 px-2">
          <div>
            <Space.Compact size="large">
              <Search
                placeholder="Search payment method"
                allowClear
                onChange={(e) => handleSearchText(e.target.value)}
                onSearch={() => handleOnSearchChange()}
              />
            </Space.Compact>
          </div>
        </div>
        <div className="pt-4">
          {filters ? (
            <>
              <div className="pt-4">
                <Table
                  columns={columns}
                  dataSource={sortedFilteredPurchases}
                  size="middle"
                  pagination={{ defaultPageSize: 10, size: "middle" }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="pt-4">
                <Table
                  columns={columns}
                  dataSource={sortedPurchases}
                  size="middle"
                  pagination={{ defaultPageSize: 10, size: "middle" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountPurchases;
