import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expenses: [],
  outstandings: [],
  debtors: [],
  sales: [],
  transactions: [],
  payments: [],
  purchases: [],
  accountDetails: "",

  filteredPayments: [],
  filteredExpenses: [],
  filteredSales: [],
  filteredTransactions: [],
  filteredDebtors: [],
  filteredPurchases: [],
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    addAccountExpenses(state, action) {
      state.expenses = action.payload;
    },

    addAccountDebtors(state, action) {
      state.debtors = action.payload;
    },

    addAccountSales(state, action) {
      state.sales = action.payload;
    },

    addAccountTransactions(state, action) {
      state.transactions = action.payload;
    },

    addAccountOutstanding(state, action) {
      state.outstandings = action.payload;
    },

    addAccountPayments(state, action) {
      state.payments = action.payload;
    },

    addAccountPurchases(state, action) {
      state.purchases = action.payload;
    },

    addFilteredAccountsDebtors(state, action) {
      state.filteredDebtors = action.payload;
    },

    addFilteredAccountsExpenses(state, action) {
      state.filteredExpenses = action.payload;
    },

    addFilteredAccountsTransactions(state, action) {
      state.filteredTransactions = action.payload;
    },

    addFilteredAccountsSales(state, action) {
      state.filteredSales = action.payload;
    },

    addFilteredAccountsPayments(state, action) {
      state.filteredPayments = action.payload;
    },

    addFilteredAccountsPurchases(state, action) {
        state.filteredPurchases = action.payload;
      },
  },
});

export const {
  addAccountExpenses,
  addAccountDebtors,
  addAccountOutstanding,
  addAccountPayments,
  addAccountSales,
  addAccountTransactions,
  addAccountPurchases,
  addFilteredAccountsDebtors,
  addFilteredAccountsExpenses,
  addFilteredAccountsPayments,
  addFilteredAccountsSales,
  addFilteredAccountsTransactions,
  addFilteredAccountsPurchases,
} = accountSlice.actions;

export const selectAccountExpenses = (state) => state.account.expenses;
export const selectAccountDebtors = (state) => state.account.debtors;
export const selectAccountSales = (state) => state.account.sales;
export const selectAccountTransactions = (state) => state.account.transactions;
export const selectAccountOutstandings = (state) => state.account.outstandings;
export const selectAccountPayments = (state) => state.account.payments;
export const selectAccountPurchases = (state) => state.account.purchases;
export const selectFilteredAccountsSales = (state) =>
  state.account.filteredSales;
export const selectFilteredAccountsDebtors = (state) =>
  state.account.filteredDebtors;
export const selectFilteredAccountsExpenses = (state) =>
  state.account.filteredExpenses;
export const selectFilteredAccountsTransactions = (state) =>
  state.account.filteredTransactions;
export const selectFilteredAccountsPayments = (state) =>
  state.account.filteredPayments;
export const selectFilteredAccountsPurchases = (state) =>
    state.account.filteredPurchases;

export default accountSlice.reducer;
