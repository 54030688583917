// export const firebaseConfig = {
//   apiKey: "AIzaSyAOb1KwMCjJ2wy1L65CIzrfkFe2Lu5g9A4",
//   authDomain: "fuel-flow-manager.firebaseapp.com",
//   projectId: "fuel-flow-manager",
//   storageBucket: "fuel-flow-manager.appspot.com",
//   messagingSenderId: "132313684902",
//   appId: "1:132313684902:web:aa3be46124314b7c1b0d67",
// };


//production data
export const firebaseConfig = {
  apiKey: "AIzaSyBGJgamXHip2yR0R7HX03SSM7U8UlmetYA",
  authDomain: "ksk-oil.firebaseapp.com",
  projectId: "ksk-oil",
  storageBucket: "ksk-oil.appspot.com",
  messagingSenderId: "272191890678",
  appId: "1:272191890678:web:a05a22628402c06b7b38d7"
};
