import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFunctions, httpsCallable } from "firebase/functions";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Box, Modal } from "@mui/material";
import { DatePicker, Select } from "antd";
import download from "../../assets/images/download_1.png";
import { selectCustomerDetails } from "../../features/customerSlice";
import toast from "react-hot-toast";

const { RangePicker } = DatePicker;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const CustomerStatement = ({ customerID }) => {
  const [pdfSrc, setPdfSrc] = useState("");
  const [filterType, setFilterType] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [range, setRange] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [reportData, setReportData] = useState("");
  const customer = useSelector(selectCustomerDetails);

  const dispatch = useDispatch();

  const functions = getFunctions();

  useEffect(() => {
    const getCustomerStatement = async () => {
      setLoading(true);
      try {
        const getData = httpsCallable(functions, "customerFinancialStatements");
        getData({ id: customerID })
          .then((result) => {
            const data = result.data;
            const statement = data?.data;
            setReportData(statement);
            setLoading(false);
            console.log(statement);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getCustomerStatement();
  }, [dispatch, customerID]);

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setFilterType(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleDayChange = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    setLoading(true);
      try {
        const getData = httpsCallable(functions, "dailyCustomerFinancialStatement");
        getData({ id: customerID, startDate: dateString[0],  endDate: dateString[1], })
          .then((result) => {
            const data = result.data;
            const statement = data?.data;
            setReportData(statement);
            setLoading(false);
            console.log(statement);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
  };

  const handleMonthChange = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    setLoading(true);
      try {
        const getData = httpsCallable(functions, "monthlyCustomerFinancialStatement");
        getData({ id: customerID, startMonth: dateString[0],  endMonth: dateString[1], })
          .then((result) => {
            const data = result.data;
            const statement = data?.data;
            setReportData(statement);
            setLoading(false);
            console.log(statement);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
  };

  const handleYearChange = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    setLoading(true);
      try {
        const getData = httpsCallable(functions, "annualCustomerFinancialStatement");
        getData({ id: customerID, startYear: dateString[0],  endYear: dateString[1], })
          .then((result) => {
            const data = result.data;
            const statement = data?.data;
            setReportData(statement);
            setLoading(false);
            console.log(statement);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const generatePDF = () => {
    if (reportData) {
      const doc = new jsPDF({
        orientation: "landscape", // A4 size in inches
      });
      const rowCount = 7;
      const startX = 10;
      const startY = 20;
      const regularRowHeight = 7;
      const firstRowHeight = 9; // Adjust the height of the first row
      const lineHeight = 5;
      const columnWidth = 39.6;

      // Set font size and style for the document
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      doc.setDrawColor(0); // Set draw color to black
      doc.setFillColor(194, 219, 176); // Set fill color
      doc.rect(
        startX,
        startY - lineHeight / 2,
        columnWidth * 7,
        firstRowHeight
      ); // Draw border for the rectangle
      doc.rect(
        startX,
        startY - lineHeight / 2,
        columnWidth * 7,
        firstRowHeight,
        "F"
      );
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0); // Set text color to black
      doc.text(
        `${customer?.name?.toUpperCase()} ${reportData?.title?.toUpperCase()}`,
        startX + (columnWidth * 7) / 2,
        startY + firstRowHeight / 2,
        { align: "center" }
      );

      let y = startY + firstRowHeight;
      const columnOneTitles = [
        "BALANCE BD",
        "FUEL TAKEN",
        "PAYMENT",
        "BALANCE CD",
      ];
      const columnTwoData = [
        `TZS ${reportData?.openingBalance || 0}`,
        `TZS ${reportData?.fuelTaken || 0}`,
        `TZS ${reportData?.totalPayment || 0}`,
        `TZS ${reportData?.closingBalance || 0}`,
      ];
      for (let i = 2; i <= 5; i++) {
        doc.setFillColor(255); // Set fill color to white
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.rect(
          startX,
          y - lineHeight / 2,
          columnWidth * 7,
          regularRowHeight,
          "F"
        ); // Draw filled rectangle

        doc.rect(startX, y - lineHeight / 2, columnWidth * 7, regularRowHeight); // Draw border for the rectangle
        doc.rect(
          startX + columnWidth * 2,
          y - lineHeight / 2,
          columnWidth * 5,
          regularRowHeight
        );
        // Draw border for the row
        doc.rect(startX, y - lineHeight / 2, columnWidth * 7, regularRowHeight);

        // Column 1 title
        doc.text(columnOneTitles[i - 2], startX + 2, y + regularRowHeight / 4, {
          align: "left",
          baseline: "middle",
        });

        // Column 2 data
        doc.text(columnTwoData[i - 2], 285, y + regularRowHeight / 4, {
          align: "right",
          baseline: "middle",
        });

        y += regularRowHeight;
      }

      // Row 6
      doc.setDrawColor(0); // Set draw color to black
      doc.rect(startX, y - lineHeight / 2, columnWidth * 7, regularRowHeight); // Draw border for the rectangle
      y += regularRowHeight;

      // Row 7: Data Headers and Data Rows
      const row7Headers = [
        "DATE",
        "DETAILS",
        "STATION",
        "FUEL",
        "LITRES",
        "PRICE",
        "AMOUNT",
        "PAYMENT",
        "BALANCE",
      ];
      y += regularRowHeight; // Move to Row 7
      doc.setFillColor(83, 126, 201);
      doc.rect(
        startX,
        y - lineHeight / 2,
        columnWidth * 7,
        regularRowHeight,
        "F"
      );
      doc.setTextColor(255);
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      // Draw Data Rows
      const saleData = reportData?.combinedData || [];
      const rowData = saleData?.sort((a, b) => new Date(a[0]) - new Date(b[0]));

      const availableWidth = columnWidth * 7; // Total available width for the row
      const columnW = availableWidth / row7Headers.length; // Calculate width for each column

      // Draw Column Headers
      const reducedColumnWidth = columnWidth / 2;
      const headerWidths = []; // Array to store calculated widths of each header
      let xPosition = startX; // Initial x position for drawing column headers
      const sY = 20 + rowCount * regularRowHeight; // Adjust startY to place headers on the eighth row

      for (let i = 0; i < row7Headers.length; i++) {
        let calculatedWidth = columnWidth; // Default width

        // Adjust width for DATE, PRICE, LITRES columns
        if (
          row7Headers[i] === "DATE" 
        ) {
          calculatedWidth = reducedColumnWidth + reducedColumnWidth / 5;
        } else if(row7Headers[i] === "LITRES" ||
          row7Headers[i] === "FUEL" ||
          row7Headers[i] === "PRICE") {
            calculatedWidth = reducedColumnWidth + reducedColumnWidth / 9;
        } else if (
          row7Headers[i] === "DETAILS" ||
          row7Headers[i] === "STATION"
        ) {
          calculatedWidth = columnWidth + reducedColumnWidth * 0.1;
        } else if (row7Headers[i] === "AMOUNT") {
          calculatedWidth = reducedColumnWidth + reducedColumnWidth / 4;
        }

        headerWidths.push(calculatedWidth); // Store calculated width

        // Draw header
        doc.rect(
          xPosition,
          sY - lineHeight / 7,
          calculatedWidth,
          regularRowHeight
        );
        doc.text(
          row7Headers[i],
          xPosition + calculatedWidth / 2,
          sY + regularRowHeight / 2,
          {
            align: "center",
            baseline: "middle",
          }
        );

        xPosition += calculatedWidth;
      }

      const columnWidths = row7Headers.map((header, index) => {
        let calculatedWidth = columnWidth; // Default width

        // Adjust width for specific columns
        if (header === "DATE") {
          calculatedWidth = reducedColumnWidth + reducedColumnWidth / 5;
        } else if(header === "PRICE" ||  header === "FUEL" || header === "LITRES"){
          calculatedWidth = reducedColumnWidth + reducedColumnWidth / 9;
        } else if (header === "DETAILS" || header === "STATION") {
          calculatedWidth = columnWidth + reducedColumnWidth * 0.1;
        } else if (header === "AMOUNT") {
          calculatedWidth = reducedColumnWidth + reducedColumnWidth / 4;
        }

        return calculatedWidth;
      });

      doc.setTextColor(0);
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      // Draw Data Rows

      for (let i = 0; i < rowData.length; i++) {
        y += regularRowHeight;
        const data = rowData[i];
        let xPosition = startX;
        for (let j = 0; j < data.length; j++) {
          let calculatedWidth = columnWidth; // Default width

          // Adjust width for specific columns
          if (j === 0) {
            calculatedWidth = reducedColumnWidth + reducedColumnWidth / 5;
          } else if(j === 3 || j === 4 || j === 5){
            calculatedWidth = reducedColumnWidth + reducedColumnWidth / 9;
          } else if (j == 1 || j == 2) {
            calculatedWidth = columnWidth + reducedColumnWidth * 0.1;
          } else if (j == 6) {
            calculatedWidth = reducedColumnWidth + reducedColumnWidth / 4;
          }

          doc.rect(
            xPosition,
            y - lineHeight / 2,
            calculatedWidth,
            regularRowHeight
          );
          if (j > 3) {
            doc.text(
              data[j],
              xPosition + calculatedWidth - 2,
              y + lineHeight / 4,
              {
                align: "right",
                baseline: "middle",
              }
            );
          } else {
            doc.text(data[j], xPosition + 2, y + lineHeight / 4, {
              align: "left",
              baseline: "middle",
            });
          }
          xPosition += calculatedWidth;
        }
      }

      doc.save("rows.pdf");
    } else {
      toast.error("Sorry! please wait for data generation");
    }
  };

  return (
    <div className="relative">
      {loading ? (
        <div className="py-4 w-full flex justify-center items-center overflow-hidden">
          <div className="absolute bg-white bg-opacity-70 z-10 h-screen w-full flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 mb-4"></div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-row gap-4 justify-end">
        <div>
          <h4 className="font-semibold py-2">Filtres:</h4>
        </div>
        <div>
          <Select
            showSearch
            placeholder="Select filter type"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            className="w-[200px]"
            options={[
              {
                value: "all",
                label: "All",
              },
              {
                value: "days",
                label: "Days",
              },
              {
                value: "months",
                label: "Months",
              },
              {
                value: "years",
                label: "Years",
              },
            ]}
          />
        </div>
        <div>
          {filterType === "months" ? (
            <RangePicker picker="month" onChange={handleMonthChange} />
          ) : (
            <>
              {filterType === "years" ? (
                <RangePicker
                  picker="year"
                  id={{
                    start: "startInput",
                    end: "endInput",
                  }}
                  onFocus={(_, info) => {
                    console.log("Focus:", info.range);
                  }}
                  onBlur={(_, info) => {
                    console.log("Blur:", info.range);
                  }}
                  onChange={handleYearChange}
                />
              ) : (
                <>
                  {filterType === "days" ? (
                    <RangePicker onChange={handleDayChange} />
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <div className="h-full flex flex-row justify-center">
          <img
            src={`${download}`}
            alt={"download report"}
            className="h-[180px] pt-8 cursor-pointer hover:animate-shake hover:animate-twice"
            loading="lazy"
            onClick={generatePDF}
          />
        </div>
        {pdfSrc && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="rounded-md">
              <iframe
                title="ORDER BOOK PREVIEW"
                src={pdfSrc}
                style={{
                  width: "100%",
                  height: "500px",
                  border: "1px solid black",
                }}
              />
            </Box>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default CustomerStatement;
