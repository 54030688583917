import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import StationDrivers from "../stations/drivers/StationDrivers";
import StationPrices from "../stations/prices/StationPrices";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../features/userSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#0A365C";

const ManagerSettings = () => {
  const [value, setValue] = useState(0);
  const user = useSelector(selectUserInfo);

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    } else {
      console.error("Invalid value: ", newValue);
    }
  };

  const renderUserTabs = () => {
    return (
      <div>
        <h4 className="text-center font-light text-xl py-2">
          Manager Settings
        </h4>
        <Box
          sx={{
            width: "100%",
            borderBottom: 1,
            borderColor: "divider",
          }}
          className="flex flex-row justify-between"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            textColor={primary}
            indicatorColor="primary"
            sx={{ textColor: primary }}
          >
            <Tab label="PRICES" {...a11yProps(0)} />
            <Tab label="DRIVERS" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <StationPrices stationID={user?.stationID}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StationDrivers  stationID={user?.stationID}/>
        </TabPanel>
      </div>
    );
  };

  return (
    <Grid container sx={{ px: 2 }}>
      <Grid item sm={12}>
        {renderUserTabs()}
      </Grid>
    </Grid>
  );
};

export default ManagerSettings;
