import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFunctions, httpsCallable } from "firebase/functions";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FaDownload } from "react-icons/fa6";
import { Box, IconButton, Modal } from "@mui/material";
import moment from "moment";
import { formatter } from "../../helpers/Helpers";
import { DatePicker, Select } from "antd";
import download from "../../assets/images/download_1.png";
import toast from "react-hot-toast";

const { RangePicker } = DatePicker;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const CustomerInvoice = ({ customerID }) => {
  console.log("invoice", customerID);
  const [pdfSrc, setPdfSrc] = useState("");
  const [filterType, setFilterType] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [range, setRange] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [reportData, setReportData] = useState("");

  const dispatch = useDispatch();

  const functions = getFunctions();

  useEffect(() => {
    const getCustomerInvoice = async () => {
      setLoading(true);
      try {
        const getData = httpsCallable(functions, "customerInvoice");
        getData({ id: customerID })
          .then((result) => {
            const data = result.data;
            const invoiceData = data?.data;
            setReportData(invoiceData);
            console.log(invoiceData);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getCustomerInvoice();
  }, [dispatch, customerID]);

  const totalAmount = 0;

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setFilterType(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleDayChange = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    setLoading(true);
    try {
      const getData = httpsCallable(functions, "dailyCustomerInvoice");
      getData({
        id: customerID,
        startDate: dateString[0],
        endDate: dateString[1],
      })
        .then((result) => {
          const data = result.data;
          const statement = data?.data;
          setReportData(statement);
          setLoading(false);
          console.log(statement);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleMonthChange = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    setLoading(true);
    try {
      const getData = httpsCallable(functions, "monthlyCustomerInvoice");
      getData({
        id: customerID,
        startMonth: dateString[0],
        endMonth: dateString[1],
      })
        .then((result) => {
          const data = result.data;
          const statement = data?.data;
          setReportData(statement);
          setLoading(false);
          console.log(statement);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleYearChange = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    setLoading(true);
    try {
      const getData = httpsCallable(functions, "annualCustomerInvoice");
      getData({
        id: customerID,
        startYear: dateString[0],
        endYear: dateString[1],
      })
        .then((result) => {
          const data = result.data;
          const statement = data?.data;
          setReportData(statement);
          setLoading(false);
          console.log(statement);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const generatePDF = () => {
    if (reportData) {
      const doc = new jsPDF();

      const startY = 20;

      // Add Invoice title
      doc.setFontSize(20);
      doc.setFont("helvetica", "normal");
      doc.text(`CUSTOMER INVOICE`, 105, 20, null, null, "center");
      doc.setFontSize(12);
      doc.text(
        `(${reportData?.title?.toUpperCase()})`,
        105,
        20,
        null,
        null,
        "center"
      );

      // Add Billed To section
      doc.setFontSize(12);
      doc.text("BILLED TO:", 15, 30);
      doc.text(`${reportData?.customer?.name}`, 15, 40);
      doc.text(`${reportData?.customer?.phone}`, 15, 45);
      doc.text("Dar Es Salaam", 15, 50);

      // Add Invoice Details
      doc.text("Invoice No. 12345", 150, 30);
      doc.text(`${moment().format("LL")}`, 150, 35);

      // Add Table
      doc.autoTable({
        startY: 60,
        head: [["Date", "Item", "Station", "Litres", "Price", "Total Amount"]],
        body: reportData?.expenses,
      });

      // Add Total section
      const finalY = doc.autoTable.previous.finalY;
      doc.setFontSize(12);
      doc.text(`Total: TZS ${reportData?.totalDebt}`, 150, finalY + 35);

      // Add Payment Information
      doc.setFontSize(12);
      doc.text("Thank you.", 20, finalY + 40);
      doc.text("PAYMENT INFORMATION", 20, finalY + 50);
      doc.text("Briard Bank", 20, finalY + 55);
      doc.text("Account Name: KSK OIL", 20, finalY + 60);
      doc.text("Account No.: 123-456-7890", 20, finalY + 65);

      // Save the PDF
      doc.save("invoice.pdf");
    } else {
      toast.error("Sorry! please wait for data generation");
    }
  };

  return (
    <div className="relative">
      {loading ? (
        <div className="py-4 w-full flex justify-center items-center overflow-hidden">
          <div className="absolute bg-white bg-opacity-70 z-10 h-screen w-full flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 mb-4"></div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-row gap-4 justify-end">
        <div>
          <h4 className="font-semibold py-2">Filtres:</h4>
        </div>
        <div>
          <Select
            showSearch
            placeholder="Select filter type"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            className="w-[200px]"
            options={[
              {
                value: "all",
                label: "All",
              },
              {
                value: "days",
                label: "Days",
              },
              {
                value: "months",
                label: "Months",
              },
              {
                value: "years",
                label: "Years",
              },
            ]}
          />
        </div>
        <div>
          {filterType === "months" ? (
            <RangePicker picker="month" onChange={handleMonthChange} />
          ) : (
            <>
              {filterType === "years" ? (
                <RangePicker
                  picker="year"
                  id={{
                    start: "startInput",
                    end: "endInput",
                  }}
                  onFocus={(_, info) => {
                    console.log("Focus:", info.range);
                  }}
                  onBlur={(_, info) => {
                    console.log("Blur:", info.range);
                  }}
                  onChange={handleYearChange}
                />
              ) : (
                <>
                  {filterType === "days" ? (
                    <RangePicker onChange={handleDayChange} />
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <div className="h-full flex flex-row justify-center">
          <img
            src={`${download}`}
            alt={"download report"}
            className="h-[180px] pt-8 cursor-pointer hover:animate-shake hover:animate-twice"
            loading="lazy"
            onClick={generatePDF}
          />
        </div>
        {pdfSrc && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="rounded-md">
              <iframe
                title="ORDER BOOK PREVIEW"
                src={pdfSrc}
                style={{
                  width: "100%",
                  height: "500px",
                  border: "1px solid black",
                }}
              />
            </Box>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default CustomerInvoice;
