import React, { useEffect, useState } from "react";
import { db } from "../../App";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import Description from "../common/Description";
import {
  addAccountDebtors,
  selectAccountDebtors,
} from "../../features/accountSlice";
import { formatter } from "../../helpers/Helpers";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Customer",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "Station",
    dataIndex: "stationName",
    key: "stationName",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Truck",
    dataIndex: "truck",
    key: "truck",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Fuel",
    dataIndex: "fuel",
    key: "fuel",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: (text) => <p>{formatter.format(text)} Litres</p>,
  },
  {
    title: "Price",
    dataIndex: "customerPrice",
    key: "customerPrice",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "View",
    key: "view",
    render: (_, debtor) => (
      <div className="flex flex-row gap-1 justify-start">
        <Description data={debtor} title={"Debtor Description"} />
      </div>
    ),
  },
];

const AccountDebtors = () => {
  const [pageLoading, setPageLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getDebtors = async () => {
      let debtorsArray = [];

      setPageLoading(true);
      const querySnapshot = await getDocs(collection(db, "debtorBucket"));
      querySnapshot.forEach((doc) => {
        //set data
        const data = doc.data();
        debtorsArray.push(data);
      });

      if (debtorsArray.length > 0) {
        dispatch(addAccountDebtors(debtorsArray));
        setPageLoading(false);
      } else {
        dispatch(addAccountDebtors([]));
        setPageLoading(false);
      }
    };

    getDebtors();
  }, [dispatch]);

  const debtors = useSelector(selectAccountDebtors);

  const sortedDebtors = debtors
    .slice()
    .sort((a, b) => new Date(b.day) - new Date(a.day))
    .map((debtor, index) => ({ ...debtor, key: index + 1 }));

  return (
    <div className="relative">
      {pageLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-hidden z-10">
          <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-70 flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 mb-4"></div>
          </div>
        </div>
      )}
      <div className="px-2">
        <div className="pt-4">
          <Table
            columns={columns}
            dataSource={sortedDebtors}
            size="middle"
            pagination={{ defaultPageSize: 10, size: "middle" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountDebtors;
