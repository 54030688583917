import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { Button, TextField } from "@mui/material";
import { colors } from "../../../assets/utils/colors";

const StationNewBook = () => {
  const [newBookDate, setNewBookDate] = useState("");
  const [loading, setLoading] = useState(false);

  const functions = getFunctions();

  const { stationID } = useParams();

  const createNewBook = async (e) => {
    e.preventDefault();

    if (!newBookDate) {
      toast.error("Please select new book date");
    } else {
      //start registration
      const dateObj = new Date(newBookDate);
      const formattedDate = dateObj
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          timeZone: "UTC",
        })
        .replace(/\//g, "-");

      console.log(formattedDate);
      setLoading(true);

      //create manager
      const addNewBook = httpsCallable(functions, "createNewBook");
      addNewBook({
        currentDate: formattedDate,
        id: stationID,
      })
        .then((result) => {
          // Read result of the Cloud Function.
          const data = result.data;
          setLoading(false);
          setNewBookDate("");

          toast.success(data.message);
        })
        .catch((error) => {
          const message = error.message;
          setLoading(false);
          toast.error(message);
        });
    }
  };

  const renderButton = () => {
    if (loading) {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[100%] cursor-not-allowed"
            sx={{
              background: `${colors.primary}`,
            }}
            disabled
          >
            <svg
              className="animate-spin h-5 w-5 mr-3 ..."
              viewBox="0 0 24 24"
            ></svg>
            Loading...
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[100%]"
            sx={{
              background: `${colors.primary}`,
              "&:hover": {
                background: `${colors.bgColor6}`,
              },
            }}
            onClick={(e) => createNewBook(e)}
          >
            CREATE NEW BOOK
          </Button>
        </>
      );
    }
  };

  return (
    <div className="px-2 py-2">
      <div className="w-full py-2 flex justify-center">
        <TextField
          id="outlined-new-book"
          size="small"
          variant="outlined"
          className="w-[42%]"
          value={newBookDate}
          onChange={(e) => setNewBookDate(e.target.value)}
          type="date"
        />
      </div>
      <div className="w-full py-4 flex justify-center">
        <div className="w-[42%] py-4">{renderButton()}</div>
      </div>
    </div>
  );
};

export default StationNewBook;
